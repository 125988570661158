.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #6724D4;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

a {
  text-decoration: none;
  color: #6724D4;
}

p {
  margin-bottom: 1rem;
}

input:focus, textarea:focus, select:focus {
  color: #495057;
  /* background-color: #fff; */
  border-color: #6724D4;
  outline: 0;
  /* box-shadow: 0 0 0 0.1rem #61dafb; */
}

th:focus {
  outline: none
}

button {
  color: #fff;
  border-radius: .25rem;
  font-weight: 700;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.7rem;
  cursor: pointer;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.30s ease-in-out, box-shadow 0.30s ease-in-out;
}

button:focus {
  outline: none
}

.logo {
  height: 2.5rem;
  margin: .2rem;
}

.link {
  cursor: pointer;
  color: #6724D4;
}

.icon {
  font-size: 1.2em;
  margin-right: .8rem;
  padding: .2rem;
}

.CircularProgressbar {
  height: 12rem;
  margin: 2rem auto;
  font-family: 'Rubik', Verdana, Geneva, Tahoma, sans-serif;
}

table {
  color: #343a40;
  border-collapse: seperate;
  border-spacing: 0;
  width: 100%;
  margin: 0 1rem 1rem 0;
  background-color: transparent;
  text-align: left;
  font-size: 1.2em;
  text-transform: capitalize;
  letter-spacing: .5px;
}

table th, td {
  padding: .75rem;
  /* vertical-align: bottom; */
  min-width: 8rem;
}

table td {
  border-top: 1px solid #dee2e6;
}

input, textarea, select {
  height: 2rem;
  padding: .4rem .75rem;
  font-size: 1em;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  /* background-color: #fff; */
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  box-shadow: 0 1px 16px 0 rgba(0,0,0,.09);
}

.custom-select {
  height: 100%;
}

.custom-select > div {
  min-height: 3rem;
}

.custom-select span {
  display: none;
}

.modal-overlay {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: rgba(59, 59, 59, 0.75);
  transition: top 0.4s, opacity 0.4s;
}

.modal-main {
  transition: opacity 0.15s linear;
}

.income-circle-bar {
  margin-top: .2rem;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.react-datepicker-wrapper,
.react-datepicker__input-container,
.react-datepicker__input-container input {
  display: flex!important;
  justify-content: flex-end!important;
}

.sq-fields {
  border: 0;
  margin: 1rem;
  padding: 1rem;
}

.sq-payment-form {
  width: auto;
}

@media (max-width: 768px) {
  .m-ipad {
    display: none;
  }
}

@media (max-width: 414px) {
  .m-iPlus {
    display: none;
  }

  table th, td {
    min-width: 4rem;
    font-size: .9em;
  }

  .mobile-flex {
    flex-direction: column;
  }

}

@media (max-width: 375px) {
  .m-iX {
    display: none;
  }
}
